// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
}

.popup-content h2 {
    margin: 0 0 10px;
}

.popup-content p {
    margin: 0 0 20px;
}

.popup-actions button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-actions .popup-yes {
    background-color: #4caf50;
    color: white;
}

.popup-actions .popup-yes:hover {
    background-color: #45a049;
}

.popup-actions .popup-no {
    background-color: #f44336;
    color: white;
}

.popup-actions .popup-no:hover {
    background-color: #d32f2f;
}


// ie fixes
@import "ie-fix";
